import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { Card } from './Card';
import { PageTitle } from './PageTitle';
import { StandardSection, StandardSectionContentContainer } from './StandardSection';
import { CardContainer } from './CardContainer';
import jokerImage from '../images/parts/joker.webp';

const PartCard = styled(Card)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
    	flex: 1 1 50%;
	}
`;

export class PartsPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<PageTitle>Parts</PageTitle>
						<CardContainer>
							<PartCard 
								to="/parts/joker" 
								background={jokerImage} 
								title="Joker" 
								description="An ultralight tinywhoop racing canopy."
							/>
						</CardContainer>
					</StandardSectionContentContainer>
				</StandardSection>
			</ThemeProvider>
		)
	}
}