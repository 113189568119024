import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { Card, CardDescription, CardTitle } from './Card';
import { PageTitle } from './PageTitle';
import { PageDescription } from './PageDescription';
import { StandardSection, StandardSectionContentContainer } from './StandardSection.js';
import { CardContainer } from './CardContainer.js';

import mainframeImage from '../images/fonts/font-card-bgr-mainframe.png';
import maverickImage from '../images/fonts/font-card-bgr-maverick.png';

const FontCard = styled(Card)`
    flex: 1 1 50%;

	${CardTitle} {
		display: none;
	}

	${CardDescription} {
		text-align: center;
	}
`;

export class FontsPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<PageTitle>Fonts</PageTitle>
						<PageDescription>Customize your OSD with Tressym fonts! Built for maximum usability, with extreme attention to detail, based on core principles of type design. Painstakingly hand-antialiased with pixel-art techniques.</PageDescription>
						<CardContainer>
							<FontCard 
								to="/fonts/mainframe" 
								background={mainframeImage} 
								title="Mainframe"
								description="A font for analog Betaflight OSDs, based on iconic monospaced pixel fonts"
							/>
							<FontCard 
								to="/fonts/maverick" 
								background={maverickImage} 
								title="Maverick"
								description="A free font for HDZero goggles, inspired by the F/A-18 Hornet's HUD."
							/>
						</CardContainer>
					</StandardSectionContentContainer>
				</StandardSection>
			</ThemeProvider>
		)
	}
}