import styled from 'styled-components';
import { Paragraph } from '../theme/typography';

export const PageDescription = styled(Paragraph)`
	color: ${(props) => props.theme.colors.neutral4};
	max-width: 50rem;
	margin-bottom: 1.5rem;
	text-wrap: balance;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		margin-bottom: 2rem;
	}
`;