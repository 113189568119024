import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { HeroParagraph, Paragraph } from '../theme/typography';
import { Button  } from './Button';
import { TextLink } from './TextLink';
import { PageTitle } from './PageTitle';
import { StandardSectionContentContainer, StandardSection } from './StandardSection';
import { ContactBlock } from './ContactBlock';
import { ServalActionItemBlock } from './ServalActionItemBlock';
import { Breadcrumbs, BreadcrumbChevron, BreadcrumbLink } from './Breadcrumbs';

import MainframeExample from '../images/fonts/mainframe-example.webp';

const StyledHeroImage = styled.img`
	border-radius: 0.25rem;
	width: 100%;
`;

const ButtonContainer = styled.div`
	align-items: stretch;
	margin-bottom: 2rem;
	margin-top: 2rem;
	max-width: 32rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const TextContainer = styled.div`
	max-width: 32rem;
`;

const TopContainer = styled.div`
	max-width: 48rem;
`;

export class MainframePage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<TopContainer>
							<Breadcrumbs>
									<BreadcrumbLink to="/fonts">
										Fonts
									</BreadcrumbLink>
									<BreadcrumbChevron>&gt;</BreadcrumbChevron>
							</Breadcrumbs>
							<PageTitle>Mainframe</PageTitle>
							<HeroParagraph>A free font for Betaflight analog OSDs, inspired by classic monospaced pixel fonts. The default font on GEPRC's newest drones.</HeroParagraph>
							<ButtonContainer>
								<Button 
									as="a"
									href={`${process.env.PUBLIC_URL}/fonts/mainframe-1-0.mcm`}
									label="Download Mainframe for free"
									download
								/>
							</ButtonContainer>
						</TopContainer>
						<StyledHeroImage src={MainframeExample} alt="Mainframe font example" />
						<TextContainer>
							<Paragraph>When I was choosing a font for my first drone in Betaflight, I was surprised how many of the default font choices were trying to... reinvent the wheel. There are so many iconic monospaced pixel fonts to learn from, so I decided to make a new Betaflight font - a tribute to three classics that have shaped my life.</Paragraph>
							<Paragraph>One of them is the <TextLink link="https://style64.org/c64-truetype" label="character set used by the Commodore 64" />, the best-selling single computer model of all time. Launched in 1982, it became the first computer in millions of homes, including my cousins' in a far-away village. I spent countless hours glued to their CRT television screen, looking at these characters, playing some of the most exciting games of my life.</Paragraph>
							<Paragraph>The second one is called <TextLink link="https://en.wikipedia.org/wiki/Code_page_437" label="Code page 437" />. This is the character set of the original IBM PC. I remember spinning on a revolving chair in my dad's office, typing random things into a console. I was too young to start Space Invaders on my own, so all I got was "Bad command or file name" — a phrase forever burned in my brain, in this font.</Paragraph>
							<Paragraph>The third is <TextLink link="https://en.wikipedia.org/wiki/Fixedsys" label="Fixedsys" />, the oldest font in Microsoft Windows. This was the system font in Windows 1.0 and 2.0, and remained the default font in Notepad even for Windows 3.0.</Paragraph>
							<Paragraph>Besides referencing these iconic fonts, Mainframe's goal is usability, readability and clarity. It relies less on icons, and more on text labels. The battery icon has distinct levels, so that it's easier to judge at a glance. The pixels are optimized for the glow, blur and chromatic aberration of analog screens, evoking the CRT screens of my childhood.</Paragraph>
							<Paragraph>Mainframe is open source. Feel free to use or remix it, just link back to this page when you do! I'm releasing it under the <TextLink href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" label="Creative Commons-Attribution-Share Alike" /> license.</Paragraph>
							<ButtonContainer>
								<Button 
									as="a"
									download
									href={`${process.env.PUBLIC_URL}/fonts//fonts/mainframe-1-0.mcm`}
									label="Download Mainframe for free" 
								/>
							</ButtonContainer>
						</TextContainer>
					</StandardSectionContentContainer>
				</StandardSection>
				<ServalActionItemBlock />
				<ContactBlock />
			</ThemeProvider>
		)
	}
}