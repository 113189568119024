import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { HeroParagraph, Paragraph, ParagraphSmall, UnorderedList, UnorderedListItem } from '../theme/typography';
import { Button  } from './Button';
import { TextLink } from './TextLink';
import { PageTitle } from './PageTitle';
import { StandardSectionContentContainer, StandardSection } from './StandardSection';
import { ContactBlock } from './ContactBlock';
import { ServalActionItemBlock } from './ServalActionItemBlock';
import { Breadcrumbs, BreadcrumbChevron, BreadcrumbLink } from './Breadcrumbs';

import JokerImage from '../images/parts/joker.webp';

const StyledHeroImage = styled.img`
	border-radius: 0.25rem;
	width: 100%;
`;

const ButtonContainer = styled.div`
	align-items: stretch;
	margin-bottom: 2rem;
	margin-top: 2rem;
	max-width: 32rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const TextContainer = styled.div`
	max-width: 32rem;
`;

const TopContainer = styled.div`
	max-width: 48rem;
`;

const FeatureListItem = styled(UnorderedListItem)`
	${Paragraph} {
		margin-bottom: 0.25rem;
	}

	${ParagraphSmall} {
		color: ${(props) => props.theme.colors.neutral4};
		margin-top: 0.25rem;
	}
`;

const DoubleButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 2rem;
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex-direction: row;
	}

	${ButtonContainer} {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

export class JokerPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<TopContainer>
							<Breadcrumbs>
									<BreadcrumbLink to="/parts">
										Parts
									</BreadcrumbLink>
									<BreadcrumbChevron>&gt;</BreadcrumbChevron>
							</Breadcrumbs>
							<PageTitle>Joker</PageTitle>
							<HeroParagraph>An ultralight racing canopy offering great camera and FC protection with just 0.72 grams of weight.</HeroParagraph>
							<DoubleButtonContainer>
								<ButtonContainer>
									<Button 
										as="a"
										href={`${process.env.PUBLIC_URL}/parts/Joker 1-5 BeeEye - Canopy.stl`}
										label="Download Joker (BeeEye)"
										download
									/>
								</ButtonContainer>
								<ButtonContainer>
									<Button 
										as="a"
										href={`${process.env.PUBLIC_URL}/parts/Joker 1-5 Pinch-C03 - Canopy.stl`}
										label="Download Joker (Pinch/C03)"
										download
									/>
								</ButtonContainer>
							</DoubleButtonContainer>
						</TopContainer>
						<StyledHeroImage src={JokerImage} alt="Mainframe font example" />
						<TextContainer>
							<Paragraph>I was greatly impressed by the efficiency of <TextLink href="https://newbeedrone.com/products/hummingbird-racespec-cam-mount-tpu-3d-printed-version" target="_blank" label="Newbeedrone's RaceSpec cam mount"></TextLink> - an excellent piece of engineering! I decided to try to further improve on this already great design, and I believe I might have succeeded:</Paragraph>
							<UnorderedList>
								<FeatureListItem>
									<Paragraph>Reduced weight</Paragraph>
									<ParagraphSmall>The Joker's weight is a tiny bit smaller, at just 0.72 grams.</ParagraphSmall>
								</FeatureListItem>
								<FeatureListItem>
									<Paragraph>Increased camera protection</Paragraph>
									<ParagraphSmall>The four spikes extending beyond the lens offer better protection to the camera in crashes.</ParagraphSmall>
								</FeatureListItem>
								<FeatureListItem>
									<Paragraph>Much easier disassembly</Paragraph>
									<ParagraphSmall>I found NBD's cam mount to be very hard to remove without tugging uncomfortably hard on the camera lens, or cutting the cam mount apart. The Joker can be easily removed when needed.</ParagraphSmall>
								</FeatureListItem>
								<FeatureListItem>
									<Paragraph>Better fit</Paragraph>
									<ParagraphSmall>The base of the NBD BeeEye's lens is rectangular. The Joker adheres to its shape perfectly.</ParagraphSmall>
								</FeatureListItem>
								<FeatureListItem>
									<Paragraph>Better printability</Paragraph>
									<ParagraphSmall>The Joker is perfectly sized for a 0.4mm printing nozzle, avoiding ambiguity in printing.</ParagraphSmall>
								</FeatureListItem>
								<FeatureListItem>
									<Paragraph>Cleaner arcs</Paragraph>
									<ParagraphSmall>In my opinion, the Joker's lines are a tiny bit neater. 🙂</ParagraphSmall>
								</FeatureListItem>
							</UnorderedList>
							<Paragraph>I retained the forward camera position, which I think is one of the smartest choices in NBD's design. It prevents the camera from impacting the flight controller in crashes.</Paragraph>
							<Paragraph>The Joker is open source. Feel free to use or remix it, just link back to this page when you do! I'm releasing it under the <TextLink href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" label="Creative Commons-Attribution-Share Alike" /> license.</Paragraph>
							<DoubleButtonContainer>
								<ButtonContainer>
									<Button 
										as="a"
										href={`${process.env.PUBLIC_URL}/parts/Joker 1-5 BeeEye - Canopy.stl`}
										label="Download Joker (BeeEye)"
										download
									/>
								</ButtonContainer>
								<ButtonContainer>
									<Button 
										as="a"
										href={`${process.env.PUBLIC_URL}/parts/Joker 1-5 Pinch-C03 - Canopy.stl`}
										label="Download Joker (Pinch/C03)"
										download
									/>
								</ButtonContainer>
							</DoubleButtonContainer>
						</TextContainer>
					</StandardSectionContentContainer>
				</StandardSection>
				<ServalActionItemBlock />
				<ContactBlock />
			</ThemeProvider>
		)
	}
}