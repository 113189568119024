import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
	flex-flow: column;
	gap: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		flex-flow: row;
		justify-content: center;
		gap: 3rem;
	}
`;