import styled from 'styled-components';

export const StandardSection = styled.section`
	align-items: center;
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral1};
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
	min-height: 100vh;
	scroll-snap-type: y proximity;
`;

export const StandardSectionContentContainer = styled.div`
    box-sizing: border-box;
    padding: 1.5rem;
    width: 100%;

    @media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
        padding: 2rem;
        max-width: 80rem;
    }
`;